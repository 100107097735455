import { User } from '../modules/User.ts';
import { Model } from '../modules/Model.js';
import { html } from 'lit';
import playstoreImageEN from './imgs/badge-playstore-en.png';
import playstoreImageDE from './imgs/badge-playstore-de.png';
import appstoreImageDE from './imgs/badge-appstore-de.svg';
import appstoreImageEN from './imgs/badge-appstore-en.svg';
import { showAppDialog } from '@yoummday/ymmd-platform-core/comp/app-shell';

const getDialog = (qr, code, lang) => html`
  <p>${window.T.notice.app_howto_install}</p>
  <img alt="" class="qrcode" src=${qr} />
  <p>
    ${window.T.notice.app_auth}
    <br />
    <code>${code}</code>
  </p>
  <div class="row align-items-center store-links">
    <div class="col col text-center">
      <a
        href="https://play.google.com/store/apps/details?id=com.yoummday.app"
        target="_blank"
        class="playstore"
      >
        <img
          alt=""
          src="${{
            de: playstoreImageEN,
            en: playstoreImageDE,
          }[lang]}"
        />
      </a>
    </div>
    <div class="col col text-center">
      <a
        href="https://apps.apple.com/app/id1479425579"
        target="_blank"
        class="appstore"
      >
        <img
          alt=""
          src="${{
            de: appstoreImageEN,
            en: appstoreImageDE,
          }[lang]}"
        />
      </a>
    </div>
  </div>
`;

export const openAppAuthDialog = async () => {
  const { success, qr, code } = await Model.data.appauth();
  if (!success) {
    return;
  }
  const { lang } = User.user;

  showAppDialog({
    titleText: window.T.headline.app_auth,
    html: getDialog(qr, code, lang),
    type: 'qr',
    confirmButtonText: window.T.cta.close,
  });
};
