import type { YmdRoute } from '@yoummday/ymmd-router/dist/types';

export default <Record<string, YmdRoute>>{
  '/webphone': {
    as: 'webphone',
    hasAsideNavigation: false,
    hasHeader: false,
    module: () => import('./'),
  },
};
