/* eslint-disable no-use-before-define */
import {
  aggregateAverage,
  PhoneInstanceService,
} from '../services/phoneInstance.service';
import { phoneInstance } from '../phoneInstance';
import type { AudioStats, ConnectionStats } from '../types/phoneInstance.def';

export const logStore: string[] = [];
export const logObjectStore: object[] = [];

export class PhoneMonitorService {
  private static instance: PhoneMonitorService;
  private phoneInstanceService: PhoneInstanceService =
    new PhoneInstanceService();
  public addLog(message: string | object | unknown): void {
    const timestamp = new Date().toISOString();
    if (typeof message === 'object') {
      logObjectStore.push({
        [`${timestamp}`]: message,
      });
    } else if (typeof message === 'string') {
      logStore.push(`[${timestamp}] ${message}`);
    }
  }
  public async startMonitoring(
    parsedData: {
      audio: AudioStats;
      connection: ConnectionStats;
    },
    callId: string,
  ): Promise<void> {
    const timestamp = new Date().getTime();
    const { qualityScore } =
      this.phoneInstanceService.calculateCallStats(parsedData);
    const res = this.phoneInstanceService.calculateCallStats(parsedData);
    this.phoneInstanceService.calculateAggregateStats(res);
    if (res.qualityScore < 2.0) {
      await this.phoneInstanceService.sendCallStats(res, callId, timestamp);
    }
    phoneInstance.qualityScores.push({
      timestamp,
      qualityScore,
    });
    try {
      this.addLog(`Call Quality: ${qualityScore}`);
    } catch (error) {
      this.addLog(`Error getting stats: ${error}`);
    }
  }

  public async endMonitoring(callId: string): Promise<void> {
    if (phoneInstance.qualityScores.length !== 0) {
      const timestamp = new Date().getTime();
      await this.phoneInstanceService.sendAggregateCallStats(
        aggregateAverage,
        callId,
        timestamp,
      );
    }
  }

  public static getInstance(): PhoneMonitorService {
    if (!PhoneMonitorService.instance) {
      PhoneMonitorService.instance = new PhoneMonitorService();
    }
    return PhoneMonitorService.instance;
  }
}
