/* eslint-disable max-lines */
import moment from 'moment-timezone';
import { User } from '../../modules/User.ts';
import { Model } from '../../modules/Model.js';
import { html, render } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { when } from 'lit/directives/when.js';
import { phoneInstance } from '../web-phone/phoneInstance';
import {
  br,
  substitutePlaceholder,
  formatAsDuration,
  clock,
} from '@yoummday/ymmd-platform-core/utils';
import Template from '../../modules/Template.js';
import TurndownService from 'turndown';
import { htmlConverter } from '@yoummday/ymmd-markdown2html';
import '@yoummday/ymmd-platform-core/comp/noteform-viewer';
import { CallService } from '../../services/CallService.js';
import { openCallQualityRatingDialog } from '../../modal/callqualityrating.js';
import '@yoummday/ymmd-platform-core/comp/phone-number';
import '../web-phone/index.ts';
import '@shoelace-style/shoelace/dist/components/button-group/button-group.js';
import {
  getCurrentView,
  showAppDialog,
} from '@yoummday/ymmd-platform-core/comp/app-shell';

let startCallOptOut = true;
export default class Call extends Template {
  constructor(...args) {
    super(...args);
    this.recording = false;
    this.isSaved = false;
    this.arbitraryForwardError = false;
    this.mdConverter = new TurndownService({ emDelimiter: '*' });
    window.addEventListener('beforeunload', (e) => this.warnOnBeforeUnload(e));
    this.hideStatusSelect = true;
    this.hideRecallSettings = true;
    this.resultUnset = false;
    this.obSettings = null;
    this.callbackSearch = null;
  }

  warnOnBeforeUnload(e) {
    if (!this.isSaved) {
      e.preventDefault();
      e.returnValue = ''; // chrome wants this
    } else {
      window.unloaded = true;
    }
  }

  getOutboundNumber(call) {
    if (call.isOb) {
      if (call.outboundcall.number) {
        return call.outboundcall.number;
      } else if (call.outboundcall.hasPhone1) {
        return call.outboundcall.phone1;
      } else if (call.outboundcall.hasPhone2) {
        return call.outboundcall.phone2;
      }
    }
    return '';
  }
  processCall() {
    const d = this.rawData.call;
    this.isSaved = !!d.voicesample;
    d.isOb = !!d.outboundcall;
    d.number = d.number || this.getOutboundNumber(d);
    d.name = d.isOb ? d.outboundcall.name : d.name;
    d.extURL = d.isOb ? d.outboundcall.extURL : d.extURL;
    d.howto = d.isOb
      ? htmlConverter
          .render(d.outboundjob.descr || '')
          .replace(/[\r\n]+/gmu, '')
      : htmlConverter.render(d.module.howto || '').replace(/[\r\n]+/gmu, '') ||
        '';
    d.callbackId = d.callId;
    d.callId = d.isOb ? d.outboundcall.id : d.callId;
    d.noteform = d.isOb ? d.outboundcall.notes : d.module.notes;
    d.recallPlanned = d.isOb && d.outboundcall.state === 'recall';

    return d;
  }

  async init() {
    await super.init(true);
    const { call = {} } = this.d;
    if (call.callId) {
      phoneInstance.callId = call.callId;
    }
    if (CallService.isOutbound(call)) {
      ({ obSettings: this.obSettings } = await import(
        '../../subtemplates/call/obSettings.js'
      ));
    }
    if (CallService.isCallback(call)) {
      ({ callbackSearch: this.callbackSearch } = await import(
        '../../subtemplates/call/callbackSearch.js'
      ));
    }
    this.requestUpdate();
  }

  callSaved(isPersonalRecall, mailsent = null) {
    this.isSaved = true;
    window.removeEventListener('beforeunload', this.warnOnBeforeUnload);
    this.showMessage(
      window.T.alert.success.saved +
        (mailsent === 1 ? ` & ${window.T.alert.success.mailsent}` : ''),
    );
    this.requestUpdate();
    CallService.logTyping();

    const currentView = getCurrentView();
    if (
      currentView.d?.outboundcalls &&
      (isPersonalRecall ||
        (this.d.call.isOb && this.d.call.outboundcall.recallTime))
    ) {
      const { calls = [] } = currentView.d.outboundcalls;
      if (!calls.some((c) => c.id === this.d.call.outboundcall.id)) {
        calls.push(this.d.call.outboundcall);
      }
      currentView.requestUpdate();
    }
  }

  askRecordingCompleteTemplate() {
    const setRecordingAllowance = (value) => {
      Model.data.voicerecorder({ mode: value });
    };

    if (
      this.d.call.module.askRecordingComplete === 'optout' &&
      startCallOptOut
    ) {
      Model.data.voicerecorder({ mode: 'setComplete' });
      startCallOptOut = false;
    }
    return html`
      <p><strong>${window.T.question.call_record}</strong></p>
      <yd-switch
        onValue="setComplete"
        offValue="unsetComplete"
        .isOn=${this.d.call.module.askRecordingComplete === 'optout'}
        @change=${(e) => setRecordingAllowance(e.currentTarget.value)}
      ></yd-switch>
    `;
  }

  ivrInfoTemplate(consented) {
    return html`
      <sl-alert open>
        <iconify-icon
          icon="material-symbols:info-outline-rounded"
          slot="icon"
        ></iconify-icon>
        <b>${window.T.hint.ivr_consent.headline}</b>
        <br />
        ${consented
          ? window.T.hint.ivr_consent.yes
          : window.T.hint.ivr_consent.no}
      </sl-alert>
    `;
  }

  askRecordingStartStopTemplate() {
    const startStopRecording = async (action = '') => {
      if (!['start', 'stop'].includes(action)) return;
      const result = await Model.data.voicerecorder({
        mode: action,
      });
      this.recording = result && result.success && action === 'start';
      this.requestUpdate();
    };

    return html`
      <p class="mt-3"><strong>${window.T.question.call_startstop}</strong></p>
      <sl-button-group>
        <yd-tooltip
          .tip=${this.recording
            ? window.T.hint.recording
            : window.T.cta.call_record}
        >
          <sl-button
            @click=${() => startStopRecording('start')}
            ?disabled=${this.recording}
          >
            <iconify-icon
              icon="mdi-record-rec"
              width="25"
              slot="prefix"
              class="${classMap({
                pulsate: this.recording,
              })}"
            ></iconify-icon>
          </sl-button>
        </yd-tooltip>
        <yd-tooltip .tip=${window.T.hint.call_record_stop}>
          <sl-button
            @click=${() => startStopRecording('stop')}
            ?disabled=${!this.recording}
          >
            <iconify-icon
              icon="mdi-stop"
              width="25"
              slot="prefix"
            ></iconify-icon>
          </sl-button>
        </yd-tooltip>
      </sl-button-group>
    `;
  }

  sendMailTemplate() {
    const { module } = this.d.call;
    if (!module.email || !Object.keys(module.email || {}).length) return '';

    return html`
      <label>${window.T.question.form_mailcopy}</label>
      <select name="emailKey" required>
        <option value="" selected disabled hidden>
          ${window.T.field.placeholder.select_option}
        </option>
        <option value="no">${window.T.term.no}</option>
        ${Object.values(module.email).map(
          (pair) => html`
            <option value=${pair.key || ''}>${pair.name}</option>
          `,
        )}
      </select>
    `;
  }

  speedDialTemplate() {
    return html`
      <p>
        <strong>${window.T.question.call_forward}</strong>
        <br />
        ${window.T.hint.redirect_call}
      </p>
      <yd-table>
        <yd-table-tbody>
          ${Object.keys(this.d.call.module.speeddial).map(
            (key) => html`
              <yd-table-row>
                <yd-table-cell>
                  <iconify-icon
                    class="iconify"
                    icon="mdi-asterisk"
                  ></iconify-icon>
                  <iconify-icon
                    class="iconify"
                    icon="mdi-numeric-${key}"
                  ></iconify-icon>
                </yd-table-cell>
                <yd-table-cell>
                  ${this.d.call.module.speeddial[key].name}
                </yd-table-cell>
              </yd-table-row>
            `,
          )}
        </yd-table-tbody>
      </yd-table>
    `;
  }

  arbitraryForwardTemplate() {
    const setForward = (e) => {
      const number = e.currentTarget
        ?.closest('.row')
        ?.querySelector('input[name="number"]')?.value;
      if (number) {
        const { success, result = '' } = CallService.forwardCall(number);
        if (!success) {
          if (result === 'error') {
            this.arbitraryForwardError = true;
          }
          this.requestUpdate();
        } else {
          this.showMessage(
            substitutePlaceholder(window.T.term.call_forwarded, number),
          );
        }
      }
    };
    return html`
      <div class="row">
        <div class="col-6">
          <phone-number
            .country=${User.user?.country || ''}
            name="number"
            placeholder=${window.T.placeholder.callforwarding_phone}
          ></phone-number>
        </div>
        <div class="col-1">
          ${when(
            this.arbitraryForwardError,
            () => html`
              <yd-tooltip .tip=${window.T.hint.callforwarding_phone_invalid}>
                <span>
                  <iconify-icon icon="mdi-alert" class="iconify"></iconify-icon>
                </span>
              </yd-tooltip>
            `,
          )}
        </div>
        <div class="col-5">
          <sl-button variant="primary" @click=${setForward}>
            ${window.T.question.call_forward}
          </sl-button>
        </div>
      </div>
    `;
  }

  extURLTemplate() {
    return html`
      <sl-button
        href=${CallService.formatExternalUrl(this.d.call.extURL)}
        target="_blank"
      >
        <iconify-icon icon="mdi-open-in-new" slot="prefix"></iconify-icon>

        ${window.T.cta.link_external}
      </sl-button>
    `;
  }

  extPortalURLsTemplate() {
    const { call } = this.d;
    return html`
      <sl-button-group>
        ${call.module.extPortalURLs.map(
          (btn, i) => html`
            <sl-button href=${btn.url} target="_${call.user.id}_${i}">
              <iconify-icon icon="mdi-open-in-new" slot="prefix"></iconify-icon>
              ${btn.title || window.T.cta.link_portal}
            </sl-button>
          `,
        )}
      </sl-button-group>
    `;
  }

  // eslint-disable-next-line max-lines-per-function
  historyTemplate() {
    const { call } = this.d;
    const badgeVariants = {
      positive: 'success',
      recall: 'warning',
      negative: 'danger',
    };
    return html`
      <yd-table>
        <yd-table-tbody>
          ${this.d.call.history.map(
            (incidence) => html`
              <yd-table-row>
                <yd-table-cell>
                  ${moment(incidence.ts).format('L LT')}
                </yd-table-cell>
                <yd-table-cell>
                  ${!incidence.user
                    ? html` ${window.T.term.system} `
                    : incidence.user.name}
                </yd-table-cell>
                <yd-table-cell>
                  ${when(
                    incidence.number,
                    () => html`
                      <yd-tooltip .tip=${window.T.hint.talk_share_talent}>
                        <yd-badge variant="dark" icon="mdi-equalizer">
                          ${formatAsDuration(incidence.durationA, 's')}
                        </yd-badge>
                      </yd-tooltip>
                      <yd-tooltip .tip=${window.T.hint.talk_share_caller}>
                        <yd-badge variant="dark" icon="mdi-equalizer">
                          ${formatAsDuration(incidence.durationB, 's')}
                        </yd-badge>
                      </yd-tooltip>
                    `,
                  )}
                  ${when(!incidence.number && incidence.userStateId, () => {
                    call.userStates
                      .filter((state) => state.id === incidence.userStateId)
                      .map(
                        (state) => html`
                          <yd-badge
                            variant=${ifDefined(badgeVariants[state.state])}
                          >
                            ${state.title}
                          </yd-badge>
                        `,
                      );
                  })}
                </yd-table-cell>
                <yd-table-cell>
                  ${incidence.comment
                    ? html`
                        <i class="d-inline-block">"${incidence.comment}"</i>
                      `
                    : ''}
                </yd-table-cell>
              </yd-table-row>
            `,
          )}
        </yd-table-tbody>
      </yd-table>
    `;
  }

  tabButtonTemplate(id = '', label = '', icon = '', active = false) {
    if (!id || !label || !icon) return '';
    return html`
      <label
        for="tab-${id}"
        class="list-group-item list-group-item-action mb-0 ${classMap({
          active,
        })}"
      >
        <iconify-icon
          class="iconify me-1"
          icon=${icon}
          width="15"
        ></iconify-icon>
        ${label}
      </label>
    `;
  }

  ready() {
    super.ready();
    window.scrollTo(0, 0); // just onload force scroll to be on top
  }

  async sendSMS(callId) {
    const { templates } = await Model.data.externalrequesttemplatesoutbound({
      callId,
    });

    const form = document.createElement('form');
    render(
      html`
        <select name="template" required>
          ${Object.entries(templates).map(
            ([templateKey, templateName]) => html`
              <option value=${templateKey}>${templateName}</option>
            `,
          )}
        </select>
      `,
      form,
    );

    if (callId) {
      const { value: formData } = await showAppDialog({
        titleText: `${window.T.cta.sendSMS}`,
        html: form,
        preConfirm: () =>
          form.reportValidity() ? form.serializeObject() : false,
        confirmButtonText: window.T.cta.message_send,
      });

      if (formData && callId) {
        const { success } = await Model.data.sendexternalrequestforoutbound({
          ...formData,
          callId,
        });

        if (success) this.showMessage(window.T.alert.success.sms_sent);
      }
    }
  }

  // eslint-disable-next-line max-lines-per-function
  mainTemplate() {
    const { call = {} } = this.d;
    const {
      user = {},
      module = {},
      callId = '',
      askForCallQualityRating = 0,
    } = call;
    // eslint-disable-next-line complexity, max-statements, max-lines-per-function
    const save = async (e) => {
      e.preventDefault();
      const form = e.currentTarget;
      const reassignTalentId = e.target.elements?.reassignTalentId?.value;
      if (reassignTalentId && reassignTalentId !== User.user.id) {
        const { isDismissed } = await showAppDialog({
          html: html`
            <div class="mb-2">${window.T.hint.call_reassign_consequences}</div>
            <div>${window.T.hint.call_reassign_consequences_verify}</div>
          `,
          titleText: window.T.hint.calledit_reassign_consequences_heading,
          confirmButtonText: `${window.T.cta.save} & ${window.T.cta.close}`,
        });
        if (isDismissed) return false;
        CallService.closeCall();
      }

      const mappedSerializedObject = {};
      for (const [key, value] of Object.entries(form.serializeObject())) {
        mappedSerializedObject[key] = Array.isArray(value)
          ? value.join(',')
          : value;
      }
      const formData = {
        ...mappedSerializedObject,
        id: call.callId,
        noteformId: call.noteform?.id,
      };
      if (
        formData.resultStatus &&
        formData.resultStatus !== 'voicemail' &&
        !formData.userStateId &&
        call.userStates.filter(
          (option) => option.state === formData.resultStatus,
        ).length
      ) {
        await showAppDialog({
          noDismissButton: true,
          variant: 'danger',
          titleText: window.T.term.error,
          html: html`
            <div class="mb-2 fw-bold">
              ${window.T.error.callResultMissing.title}
            </div>
            <div>${window.T.error.callResultMissing.message}</div>
          `,
          confirmButtonText: window.T.term.agreed,
        });
        this.resultUnset = true;
        this.requestUpdate();
        return false;
      }

      e.submitter?.setAttribute('disabled', '');
      const endpoint = call.isOb ? 'outboundcall' : 'inboundcall';
      const {
        call: resultCall,
        history,
        mailsent,
      } = await Model.data[endpoint](formData);
      e.submitter?.removeAttribute('disabled');

      if (resultCall) {
        if (call.isOb) {
          this.d.call.outboundcall = resultCall;
          this.d.call.history = history;
        }
        if (this.d.call && resultCall.notes) {
          this.d.call.noteform = resultCall.notes;
        }
        this.callSaved(resultCall.personalRecall, mailsent);

        if (e.submitter?.getAttribute('name') === 'close-after-save') {
          CallService.closeCall();
          this.container.dispatchEvent(
            new CustomEvent('destroyOverlay', {
              bubbles: true,
              composed: true,
            }),
          );
          if (askForCallQualityRating) openCallQualityRatingDialog(callId);
        }
      }

      return null;
    };

    return html`
      <yd-card>
        <div class="row">
          <div class="col">
            <h3>${window.T.message.event.call_new}</h3>
          </div>
          <div class="col-auto">
            <button
              class="btn-close"
              type="submit"
              form="notes-form"
              data-closecall="1"
            ></button>
          </div>
        </div>
        <hr />
        <div class="row g-0">
          <div class="col-auto">
            <user-thumb .user=${user} size="70"></user-thumb>
          </div>
          <div class="col-auto">
            <p>
              <strong>
                ${user.company} &ndash;
                ${module.title || window.T.term.untitled}
              </strong>
              (${clock(user.timezone)} in ${user.timezone})
              <br />
              ${call.isOb
                ? window.T.label.payment_external_user
                : window.T.term.caller}:
              <b>${call.name || window.T.term.unknown}</b>
              <br />
              ${window.T.term.phone_number}:
              <b>${call.number || window.T.term.unknown}</b>
              ${when(
                call.module.enableExternalRequests,
                () => html`
                  <sl-button
                    variant="primary"
                    class="ms-3"
                    @click=${() => this.sendSMS(callId)}
                  >
                    ${window.T.cta.sendSMS}
                  </sl-button>
                `,
              )}
            </p>
          </div>
          <h4 class="col text-end">
            ${when(
              module.targetAHT,
              () => html`
                <yd-badge variant="info">
                  ${window.T.term.targetAHT}
                  ${formatAsDuration(module.targetAHT, 's')}
                </yd-badge>
                <br />
                ${when(
                  call.myAHT,
                  () => html`
                    <yd-badge variant=${CallService.getAhtRateClass(call)}>
                      ${window.T.term.myAHT}
                      ${formatAsDuration(call.myAHT, 's')}
                    </yd-badge>
                  `,
                )}
              `,
            )}
          </h4>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-8">
            <div class="tabs">
              <input
                type="radio"
                name="tabs-call-${call.callId}"
                id="tab-notes"
                checked
              />
              <div class="tab">
                ${when(
                  module.salutation,
                  () => html`
                    <h4>
                      <iconify-icon
                        class="iconify me-1"
                        icon="mdi-voice"
                        width="20"
                      ></iconify-icon>
                      ${window.T.label.guideline_call_salutation}
                    </h4>
                    <h5 class="mb-5 mt-4">
                      <i>
                        „${call.voicesample
                          ? br(
                              substitutePlaceholder(
                                module.salutation,
                                User.user.pseudonym.trim() || User.user.name,
                                User.user.pseudonym.trim() || User.user.name,
                              ),
                            )
                          : br(module.salutation)}“
                      </i>
                    </h5>
                  `,
                )}
                ${when(
                  !call.voicesample,
                  // eslint-disable-next-line max-lines-per-function
                  () => html`
                    <h4>
                      <iconify-icon
                        class="iconify me-1"
                        icon="mdi-file-document-edit-outline"
                        width="20"
                      ></iconify-icon>
                      ${window.T.label.edit_callform}
                    </h4>
                    ${when(
                      CallService.hasIvr(call),
                      () =>
                        this.ivrInfoTemplate(
                          call.hasCustomerConsentedToRecording,
                        ),
                      () => html`
                        ${when(CallService.canAskRecording(call), () =>
                          this.askRecordingCompleteTemplate(),
                        )}
                      `,
                    )}
                    ${when(CallService.canStartStopRecording(call), () =>
                      this.askRecordingStartStopTemplate(),
                    )}
                    ${when(CallService.hasExternalUrl(call), () =>
                      this.extURLTemplate(),
                    )}
                    ${when(CallService.hasExternalPortalUrls(call), () =>
                      this.extPortalURLsTemplate(),
                    )}
                    ${when(
                      this.callbackSearch,
                      () => this.callbackSearch(this),
                      () => html`
                        <form
                          @submit=${save}
                          ?novalidate=${call.recallPlanned}
                          id="notes-form"
                        >
                          <noteform-viewer
                            .fields=${call.noteform?.fields}
                          ></noteform-viewer>
                          ${when(CallService.canSendMail(call), () =>
                            this.sendMailTemplate(),
                          )}
                          ${when(this.obSettings, () =>
                            this.obSettings(null, this),
                          )}
                          <div class="text-end">
                            <sl-button type="submit" variant="primary">
                              ${window.T.cta.save}
                            </sl-button>

                            <sl-button
                              type="submit"
                              variant="primary"
                              name="close-after-save"
                            >
                              ${window.T.cta.save_close}
                            </sl-button>
                          </div>
                        </form>
                      `,
                    )}
                  `,
                )}
              </div>

              ${when(
                call.howto,
                () => html`
                  <input
                    type="radio"
                    name="tabs-call-${call.callId}"
                    id="tab-howto"
                  />
                  <div class="tab">
                    <h4>
                      <iconify-icon
                        class="iconify me-1"
                        icon="mdi-routes"
                        width="20"
                      ></iconify-icon>
                      ${window.T.label.guideline_call_guide}
                    </h4>
                    <hr />
                    ${unsafeHTML(call.howto || '')}
                  </div>
                `,
              )}

              <input
                type="radio"
                name="tabs-call-${call.callId}"
                id="tab-speeddial"
              />
              <div class="tab">
                <h4>
                  <iconify-icon
                    class="iconify me-1"
                    icon="mdi-phone-forward"
                    width="20"
                  ></iconify-icon>
                  ${window.T.question.call_forward}
                </h4>
                <hr />
                ${when(CallService.canSpeedDial(call), () =>
                  this.speedDialTemplate(),
                )}
                ${when(CallService.canForward(call), () =>
                  this.arbitraryForwardTemplate(),
                )}
              </div>

              <input
                type="radio"
                name="tabs-call-${call.callId}"
                id="tab-history"
              />
              <div class="tab">
                <h4>
                  <iconify-icon
                    class="iconify me-1"
                    icon="mdi-history"
                    width="20"
                  ></iconify-icon>
                  ${window.T.headline.call_outbound_notes_history}
                </h4>
                <hr />
                ${when(CallService.hasHistory(call), () =>
                  this.historyTemplate(),
                )}
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="list-group list-group-flush mb-4">
              ${this.tabButtonTemplate(
                'notes',
                window.T.label.edit_callform,
                'mdi-file-document-edit-outline',
                true,
              )}
              ${when(call.howto, () =>
                this.tabButtonTemplate(
                  'howto',
                  window.T.label.guideline_call_guide,
                  'mdi-routes',
                ),
              )}
              ${when(
                CallService.canSpeedDial(call) || CallService.canForward(call),
                () =>
                  this.tabButtonTemplate(
                    'speeddial',
                    window.T.question.call_forward,
                    'mdi-phone-forward',
                  ),
              )}
              ${when(CallService.hasHistory(call), () =>
                this.tabButtonTemplate(
                  'history',
                  window.T.headline.call_outbound_notes_history,
                  'mdi-history',
                ),
              )}
            </div>
            ${when(
              phoneInstance.ua?.isConnected(),
              () => html` <web-phone></web-phone> `,
            )}
          </div>
        </div>
      </yd-card>
    `;
  }
}
