import { socket } from '@yoummday/ymmd-websocket';

const hooks = {
  after: () => {
    socket.kill();
  },
};

export default <Record<string, TalentRoute>>{
  '/assistant/:companyUserId/:moduleId': {
    as: 'assistant',
    hasHeader: false,
    hasAsideNavigation: false,
    hooks,
    module: () => import('./'),
  },
  '/assistant/:companyUserId': {
    as: 'assistant',
    hasHeader: false,
    hasAsideNavigation: false,
    hooks,
    module: () => import('./'),
  },
  '/assistant': {
    as: 'assistant',
    hasHeader: false,
    hasAsideNavigation: false,
    hooks,
    module: () => import('./'),
  },
};
