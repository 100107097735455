export default <Record<string, TalentRoute>>{
  '/account': {
    as: 'account',
    needsOnboarding: false,
    isAllowedWithoutVerifiedEmail: true,
    hasPageTracking: true,
    hasRocketchat: true,
    module: () => import('./'),
  },
  '/account/:settingsgroup': {
    as: 'account',
    needsOnboarding: false,
    isAllowedWithoutVerifiedEmail: true,
    hasPageTracking: true,
    hasRocketchat: true,
    module: () => import('./'),
  },
};
