import {
  i18n,
  type Internationalization,
  type Override,
} from '@yoummday/ymmd-platform-core';
import txtJsonShape from './en.json';
import { Storage } from '@yoummday/ymmd-client-storage';
import { apiHost } from '../modules/api';
import { Log } from '@yoummday/ymmd-logger';

Object.assign(i18n, {
  Log,
  storage: new Storage(`${import.meta.env.VITE_APPVERSION}_i18n`),
  staticDataEndpoint: `${apiHost}common/statics`,
  prismicSourceFiles: import.meta.glob(`./*.prismic.json`, {
    import: 'default',
  }),
  sourceFiles: import.meta.glob('./{de,en}.json', {
    import: 'default',
  }),
  rawText: txtJsonShape,
  txt: txtJsonShape,
});

declare const i18n: Override<
  Internationalization,
  {
    rawText: typeof txtJsonShape;
    txt: typeof txtJsonShape | null;
  }
>;

export { i18n };
