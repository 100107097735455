import { html, render } from 'lit';
import { Model } from '../modules/Model.js';
import {
  createAlertMessageEvent,
  createToastMessageEvent,
  createDestroyToastMessageEvent,
} from '@yoummday/ymmd-platform-core/comp/app-shell';
import '@shoelace-style/shoelace/dist/components/rating/rating.js';

const saveCallRating = async (callId, rating) => {
  if (!rating || !callId) return false;
  const { success } = await Model.data.ratecallquality({ id: callId, rating });
  return !!success;
};

export const openCallQualityRatingDialog = (callId) => {
  const form = document.createElement('form');
  const saveRating = async () => {
    const { grade } = form.serializeObject();
    const gradesMap = [6, 5, 4, 3, 2, 1];
    if (grade) {
      const success = await saveCallRating(callId, gradesMap[grade - 1]);
      if (success) {
        form.dispatchEvent(
          createAlertMessageEvent(window.T.alert.success.saved),
        );
      }
    }
    form.dispatchEvent(createDestroyToastMessageEvent());
  };

  render(
    html`
      <div
        style="align-items: center; justify-content: center; display: flex; flex-direction: column;"
      >
        <h3>${window.T.headline.callStarRating}</h3>
        <input type="hidden" id="grade" name="grade" />
        <sl-rating
          name="rating"
          max="6"
          @sl-change=${(e) =>
            (e.currentTarget.closest('form').querySelector('#grade').value =
              e.currentTarget.value)}
        ></sl-rating>
        <p></p>
        <sl-button @click="${(e) => saveRating(e)}">
          ${window.T.cta.message_send}
        </sl-button>
      </div>
    `,
    form,
  );
  return form.dispatchEvent(createToastMessageEvent(form, 'warning'));
};
