import type { YmdRoute } from '@yoummday/ymmd-router/dist/types';

export default <Record<string, YmdRoute>>{
  '/tfa': {
    as: 'tfa',
    hasPageTracking: true,
    // we might be in a state where the user has a BE session,
    // but FE doesn't know because it didn't receive an user object
    // due to { error: 'tfarequired' }
    // therefore, we need to set:
    isAuthRequired: false,
    // and also:
    isAllowedWithoutVerifiedEmail: true,
    // and also:
    needsOnboarding: false,
    module: () => import('./'),
  },
};
