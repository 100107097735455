const convertStringToNumericOrBoolOrNot = (string: string) => {
  if (string === 'true' || string === 'false') return string === 'true';
  return !isNaN(parseFloat(string)) && isFinite(parseFloat(string))
    ? parseFloat(string)
    : string;
};

HTMLFormElement.prototype.serializeObject = function (convertType: boolean) {
  const formEntries = new FormData(this).entries();
  const serializationOfIterator = (iterator) =>
    [...iterator]
      // transform entries map to deep structured object
      .reduce((serialized, [name, value]) => {
        // convert value to another type?
        const val = convertType
          ? convertStringToNumericOrBoolOrNot(value)
          : value;
        // occurance of arrays? separation...
        const [prop, arrProp] = name.split('[]');
        // theres about to be an array of objects
        if (arrProp) {
          // create array if not yet done
          serialized[prop] = serialized[prop] || [];
          // get the last element in the array
          const prevObj = serialized[prop].length
            ? serialized[prop][serialized[prop].length - 1]
            : null;
          // cleanup property names from '[' and ']'
          const deepProp = arrProp.replace(/[[\]]+/gu, '');
          // it's the first occurance, or in the last occurance is the current property already set
          if (!prevObj || prevObj[deepProp]) {
            // new element in array with current property set
            serialized[prop].push({ [deepProp]: val });
          } else {
            // add property to previous object in list
            prevObj[deepProp] = val;
          }
          // it's about to be a flat array
        } else if (name.includes('[]')) {
          if (!Array.isArray(serialized[prop])) {
            serialized[prop] = [];
          }
          // concat current value to existing list || or new list
          serialized[prop].push(val);
        } else {
          // nothing special here; add formdata row to object
          serialized[name] = val;
        }
        return serialized;
      }, {});
  return serializationOfIterator(formEntries);
};
