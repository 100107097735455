export default <Record<string, TalentRoute>>{
  '/mailbox/:conversationId': {
    as: 'mailbox',
    needsOnboarding: false,
    hasPageTracking: true,
    hasRocketchat: true,
    module: () => import('./'),
  },
  '/mailbox': {
    as: 'mailbox',
    needsOnboarding: false,
    hasPageTracking: true,
    hasRocketchat: true,
    module: () => import('./'),
  },
};
