import YmmdRouter from '@yoummday/ymmd-router';
import type { Match } from 'navigo';
import { User } from '../User';
import { Log } from '@yoummday/ymmd-logger';
import { matomoTracker } from '../matomoTracker';
import { socket } from '@yoummday/ymmd-websocket';

const Router = new YmmdRouter(import.meta.env.BASE_URL);

Router.notFound(() => {
  const redirectTo = User.hasSession ? '/notfound' : '/login';
  Router.navigate(redirectTo, {
    updateBrowserURL: false,
  });
  if (User.hasSession) {
    const { url } = Router.getCurrentLocation();
    Log.error(new Error(`Not found: ${url}`));
  }
});

const canAccessAccordingToOnboardingState = (
  ymdRoute: TalentRoute,
): boolean => {
  // user is NOT onboarded but the route requires being onboarded
  if (User.hasSession && !User.isOnboarded && ymdRoute.needsOnboarding) {
    Router.navigate(`/join${window.location.search}`);
    return false;
  }
  // user is onboarded, but the route is just for users who are NOT onboarded
  if (User.isOnboarded && ymdRoute.needsNoOnboarding) {
    Router.navigate('/');
    return false;
  }
  return true;
};

const sessionOk = (ymdRoute: TalentRoute, match: Match) => {
  if (ymdRoute.isAuthRequired && !User.hasSession) {
    Router.navigate('/login', {
      updateBrowserURL: false,
    });
    return false;
  }

  if (
    User.hasSession &&
    !User.emailVerified &&
    !ymdRoute.isAllowedWithoutVerifiedEmail
  ) {
    Router.navigate(
      `/welcome${match.queryString ? `?${match.queryString}` : ''}`,
    );
    return false;
  }
  return true;
};

Router.declareDefaultRouteConfig(<Omit<TalentRoute, 'as'>>{
  needsOnboarding: true,
  needsNoOnboarding: false,
  isAllowedWithoutVerifiedEmail: false,
  hasPageTracking: false,
  hasRocketchat: false,
  hooks: {
    before: (done, match, ymdRoute: TalentRoute) => {
      done(
        sessionOk(ymdRoute, match) &&
          canAccessAccordingToOnboardingState(ymdRoute),
      );
    },
    after: (match, ymdRoute) => {
      if (ymdRoute.hasPageTracking) matomoTracker.pageTrack();
      socket.initAndAuthorize(
        User.user?.websocket,
        import.meta.env.VITE_YOUMMDAY_SOCKET,
      );
    },
  },
});

export { Router };
