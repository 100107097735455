import { Model } from '../modules/Model.js';
import { User } from '../modules/User.ts';
import { phoneInstance } from '../components/web-phone/phoneInstance';
import {
  getAppElement,
  createAlertMessageEvent,
} from '@yoummday/ymmd-platform-core/comp/app-shell';
import { Log } from '@yoummday/ymmd-logger';

export const CallService = {
  getAhtRateClass({ module, myAHT }) {
    const ahtDiff = module.targetAHT / myAHT;
    let rateClass = '';
    if (ahtDiff > 0.8 && ahtDiff < 1.2) {
      rateClass = 'success';
    } else if (ahtDiff > 0.6 && ahtDiff < 1.4) {
      rateClass = 'warning';
    } else if (ahtDiff <= 0.6 || ahtDiff >= 1.4) {
      rateClass = 'danger';
    }
    return rateClass;
  },

  canAskRecording({ module = {} }) {
    return !!module.askRecordingComplete;
  },

  hasIvr(call = {}) {
    return Object.hasOwn(call, 'hasCustomerConsentedToRecording');
  },

  canStartStopRecording({ module = {} }) {
    return !!module.askRecordingStartStop;
  },

  canSendMail({ module = {} }) {
    return (
      module.email &&
      typeof module.email === 'object' &&
      Object.keys(module.email).length
    );
  },

  canSpeedDial({ module = {} }) {
    return (
      module.speeddial &&
      typeof module.speeddial === 'object' &&
      Object.keys(module.speeddial).length
    );
  },

  canForward({ module = {} }) {
    return !!module.allowExternalForward;
  },

  hasExternalUrl({ extURL }) {
    return !!extURL;
  },

  formatExternalUrl(extURL) {
    if (!extURL) return '';

    if (['http', '//', '/'].some((word) => extURL.startsWith(word))) {
      return extURL;
    }

    return `//${extURL}`;
  },

  hasExternalPortalUrls({ module = {} }) {
    return !!module.extPortalURLs;
  },

  hasHistory({ history }) {
    return !!history;
  },

  isCallback({ module = {} }) {
    return module.type && module.type === 'callback';
  },

  isOutbound({ outboundcall }) {
    return !!outboundcall;
  },

  closeCall() {
    phoneInstance.terminate();
    Model.data.unblockinbound();
  },

  async forwardCall(number = '') {
    let result = '';
    const { success, error } = await Model.data.forwardcall({ number });
    if (error) {
      if (error !== 'number') {
        getAppElement()?.dispatchEvent(
          createAlertMessageEvent(window.T.error[error], 'danger'),
        );
      } else {
        result = 'error';
      }
    }
    return { success, result };
  },

  logTyping() {
    if (!User.device.hasTouchScreen) {
      try {
        const pattern = window.Tdna.getTypingPattern({ type: 0 });
        const quality = pattern ? window.Tdna.getQuality(pattern) : 0;
        if (quality > 0.3) {
          Model.data.logtyping({ quality, value: pattern });
          window.Tdna.reset();
        }
      } catch (err) {
        Log.log(err);
      }
    }
  },
};
