import { User } from '../../modules/User';
import { SalesToolService } from '../salestool/service/sales-tool-service.js';

export default <Record<string, TalentRoute>>{
  '/adminsalestool': {
    as: 'adminsalestool',
    hasAsideNavigation: false,
    hasAsideSalesToolNavigation: true,
    module: async () => {
      const isAdminAccessAllowed = await SalesToolService.adminAccessAllowed();
      User.updateUser({ isSalesToolAdmin: isAdminAccessAllowed });
      return await import('./');
    },
  },
};
