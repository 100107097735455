import { html } from 'lit';

export const getSipDownloads = () => {
  const [path, icon, label] =
    navigator.platform.toUpperCase().indexOf('MAC') >= 0
      ? ['linphone.org/download', 'mdi-apple', 'Linphone']
      : ['microsip.org/downloads', 'mdi-windows', 'MicroSIP Lite'];
  return html`
    <sl-button target="_blank" href=${`https://www.${path}`}>
      <iconify-icon slot="prefix" icon=${icon}></iconify-icon>
      ${label}
    </sl-button>
  `;
};
