import { html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';
import { phoneInstance } from './phoneInstance';
import style from './style.scss?inline';
import { showAppDialog } from '@yoummday/ymmd-platform-core/comp/app-shell';

@customElement('web-phone')
export default class WebPhone extends LitElement {
  @property({ type: String }) env: string = '';

  @property({ type: String }) user: string = '';

  @property({ type: String }) password: string = '';

  @state() state = phoneInstance.state;

  @state() autoAnswer = phoneInstance.autoAnswer;

  popped: Window | null = null;

  static styles = [style];

  connectedCallback() {
    super.connectedCallback();
    if (!phoneInstance) return;
    (phoneInstance.guis as WebPhone[]).push(this);
    phoneInstance.user = this.user;
    phoneInstance.password = this.password;
    phoneInstance.start();
  }

  async toggleAnswerDialog(open = false) {
    if (this.env === 'standalone') return;
    if (!open) {
      return;
    }
    const { isDismissed } = await showAppDialog({
      html: '',
      type: 'phone',
      confirmButtonText: window.T.cta.call_incoming_answer,
      dismissButtonText: window.T.cta.decline,
    });
    phoneInstance[isDismissed ? 'terminate' : 'answer']();
  }

  popOut(e: MouseEvent) {
    e.preventDefault();
    if (
      phoneInstance.state === 'ringing' ||
      phoneInstance.state === 'talking'
    ) {
      return;
    }
    if (!this.popped || this.popped?.closed) {
      this.popped = window.open(
        `${
          import.meta.env.BASE_URL !== '/' ? `${import.meta.env.BASE_URL}` : ''
        }/webphone`,
        'popped',
        'width=260,height=350',
      );
    } else {
      this.popped.focus();
    }
  }

  // eslint-disable-next-line max-lines-per-function
  phoneHeaderTemplate() {
    const toggleAutoAnswer = () => {
      phoneInstance.toggleAutoAnswer();
    };
    return html`
      <yd-tooltip
        class="led ${this.state}"
        tip=${window.T.webphone[this.state] || ''}
      ></yd-tooltip>
      ${when(
        this.env === 'header' &&
          (this.state === 'ringing' || this.state === 'talking'),
        () => html`
          <sl-button
            circle
            variant="danger"
            @click=${(e) => phoneInstance.terminate(e)}
          >
            <iconify-icon icon="mdi-phone-hangup" width="18"></iconify-icon>
          </sl-button>
        `,
      )}
      <yd-tooltip .tip=${window.T.webphone.autoAnswer}>
        <sl-button
          circle
          variant=${this.autoAnswer ? 'success' : 'warning'}
          @click=${toggleAutoAnswer}
        >
          <iconify-icon icon="mdi-phone-return" width="18"></iconify-icon>
        </sl-button>
      </yd-tooltip>
      ${when(
        this.env === 'header',
        () => html`
          <yd-tooltip .tip=${window.T.webphone.popout_standalone}>
            <sl-button
              circle
              variant="neutral"
              @click=${this.popOut}
              ?disabled=${this.state === 'ringing' || this.state === 'talking'}
            >
              <iconify-icon
                icon="mdi-monitor-cellphone"
                width="18"
              ></iconify-icon>
            </sl-button>
          </yd-tooltip>
        `,
      )}
    `;
  }

  // eslint-disable-next-line max-lines-per-function
  phoneBodyTemplate() {
    return html`
      <div class="numpad">
        ${new Array(9).fill('').map(
          (_, i) => html`
            <span @click=${() => phoneInstance.sendDTMF(i + 1)}>
              <span>${i + 1}</span>
            </span>
          `,
        )}
        <span @click=${() => phoneInstance.sendDTMF('*')}>
          <iconify-icon class="iconify" icon="mdi-asterisk"></iconify-icon>
        </span>
        <span @click=${() => phoneInstance.sendDTMF(0)}>
          <span>0</span>
        </span>
        <span @click=${() => phoneInstance.sendDTMF('#')}>
          <iconify-icon class="iconify" icon="mdi-pound"></iconify-icon>
        </span>
      </div>
      ${when(
        phoneInstance.call,
        () => html`
          <div class="call-actions">
            <sl-button
              variant="danger"
              @click=${(e) => phoneInstance.terminate(e)}
            >
              <iconify-icon
                slot="prefix"
                icon="mdi-phone-hangup"
                width="24"
              ></iconify-icon>
            </sl-button>
            ${when(
              phoneInstance.state === 'ringing',
              () => html`
                <sl-button variant="success" @click=${phoneInstance.answer}>
                  <iconify-icon
                    slot="prefix"
                    icon="mdi-phone-in-talk"
                    width="24"
                  ></iconify-icon>
                </sl-button>
              `,
            )}
          </div>
        `,
      )}
    `;
  }
  render() {
    ({ state: this.state, autoAnswer: this.autoAnswer } = phoneInstance);
    return html`
      ${when(
        this.env === 'standalone',
        () => html`
          <span class="logo">
            <iconify-icon icon="ymmd-logo" width="115"></iconify-icon>
          </span>
        `,
      )}
      ${when(this.env === 'header' || this.env === 'standalone', () =>
        this.phoneHeaderTemplate(),
      )}
      ${when(this.env !== 'header', () => this.phoneBodyTemplate())}
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'web-phone': WebPhone;
  }
}
