import type {
  ArbitraryArrowFunction,
  YmdRoute,
} from '@yoummday/ymmd-router/dist/types';

export default <Record<string, YmdRoute>>{
  '/verify/:token': {
    as: 'verify',
    isAllowedWithoutVerifiedEmail: true,
    needsOnboarding: false,
    module: () => import('./'),
    uses: (match, ymdRoute, router) =>
      (ymdRoute.routeDefaultExport as ArbitraryArrowFunction)(
        match.data?.token,
        router.navigate,
      ),
  },
};
