import type { YmdRoute } from '@yoummday/ymmd-router/dist/types';

export default <Record<string, YmdRoute>>{
  '/notfound': {
    as: 'notfound',
    hasHeader: true,
    hasAsideNavigation: true,
    module: () => import('./'),
  },
};
