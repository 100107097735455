import { html, LitElement, type PropertyValues } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import style from './style.scss?inline';
import { when } from 'lit/directives/when.js';
import { socket } from '@yoummday/ymmd-websocket';
import { legacyAPI } from '../../modules/api';

@customElement('mail-indicator')
export default class MailIndicator extends LitElement {
  @property({ type: String }) companyuser: string = '';

  limit: number = 100;
  @property({ type: Number }) counter: number = 0;

  static styles = [style];

  connectedCallback(): void {
    super.connectedCallback();
    socket.subscribe(this, '*', ({ detail: { unreadmessages } }) => {
      if (unreadmessages && Number.isInteger(unreadmessages)) {
        this.counter = unreadmessages;
      }
    });
  }

  async updated(changedProperties: PropertyValues<this>) {
    if (changedProperties.has('companyuser') && this.companyuser) {
      const {
        data: { messages = [] },
      } = await legacyAPI.POST('/getconversationlist', {
        body: {
          limit: this.limit,
          unreadonly: 1,
          companyuser: this.companyuser,
        },
      });
      this.counter = messages.length;
    }
  }

  render() {
    return html`
      <iconify-icon icon="mdi-email" class="iconify" width="24"></iconify-icon>
      ${when(
        Number(this.counter) > 0,
        () => html`
          <span>
            ${this.counter >= this.limit
              ? `${this.limit - 1}+`
              : this.counter || ''}
          </span>
        `,
      )}
    `;
  }
}
declare global {
  interface HTMLElementTagNameMap {
    'mail-indicator': MailIndicator;
  }
}
