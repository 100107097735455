export default <Record<string, TalentRoute>>{
  '/contract/:contractId/:userId/:dpaStatus': {
    as: 'contract',
    hasRocketchat: true,
    module: () => import('./contract.js'),
  },
  '/contract/:contractId/:userId': {
    as: 'contract',
    hasRocketchat: true,
    module: () => import('./contract.js'),
  },
  '/contract/:contractId': {
    as: 'contract',
    hasRocketchat: true,
    module: () => import('./contract.js'),
  },
  '/contract': {
    as: 'contract',
    hasRocketchat: true,
    module: () => import('./contract.js'),
  },
  '/join': {
    as: 'join',
    needsOnboarding: false,
    needsNoOnboarding: true,
    hasPageTracking: true,
    module: () => import('./join.js'),
  },
  '/join/:page/:topic': {
    as: 'join',
    needsOnboarding: false,
    needsNoOnboarding: true,
    hasPageTracking: true,
    module: () => import('./join.js'),
  },
  '/join/:page': {
    as: 'join',
    needsOnboarding: false,
    needsNoOnboarding: true,
    hasPageTracking: true,
    module: () => import('./join.js'),
  },
  '/join/:page/:companyId/:moduleId': {
    as: 'join',
    needsOnboarding: false,
    needsNoOnboarding: true,
    hasPageTracking: true,
    module: () => import('./join.js'),
  },
  '/income': {
    as: 'income',
    hasRocketchat: true,
    module: () => import('./income.js'),
  },
  '/dialerwork/:contractId': {
    as: 'dialerwork',
    hasRocketchat: true,
    module: () => import('./dialerwork.js'),
  },
  '/outboundwork/:contractId': {
    as: 'outboundwork',
    hasRocketchat: true,
    module: () => import('./outboundwork.js'),
  },
  '/outboundwork': {
    as: 'outboundwork',
    hasRocketchat: true,
    module: () => import('./outboundwork.js'),
  },
  '/calls': {
    as: 'calls',
    hasRocketchat: true,
    module: () => import('./calls.js'),
  },
  '/schedule': {
    as: 'schedule',
    hasRocketchat: true,
    module: () => import('./schedule.js'),
  },
  '/schedule/:contractId': {
    as: 'schedule',
    hasRocketchat: true,
    module: () => import('./schedule.js'),
  },
};
