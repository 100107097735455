import { deepNestedObjectToQueryString } from '@yoummday/ymmd-api-client';
import { Storage } from '@yoummday/ymmd-client-storage';
import {
  getAppElement,
  showAppDialog,
  createAlertMessageEvent,
  createLogoutEvent,
} from '@yoummday/ymmd-platform-core/comp/app-shell';
import { Log } from '@yoummday/ymmd-logger';
import { Router } from './router';
import { i18n } from '@yoummday/ymmd-platform-core';

export default class ModelClass {
  apiHost = `${import.meta.env.VITE_APIHOST}provider/`;
  _appElement = null;
  get appElement() {
    this._appElement = this._appElement || getAppElement();
    return this._appElement;
  }
  constructor() {
    this.appVersion = import.meta.env.VITE_APPVERSION;
    this.date = 'YYYY-MM-DD';
    this.time = `${this.date} HH:mm:ss`;
    this.abortControllers = new Map();

    this.storageWhitelist = new Map();

    this.stored = new Proxy(this, {
      get: (_, callname) => this.retrieveData(callname),
    });
    this.data = new Proxy(this.stored, {
      get:
        (_, callname) =>
        (params = {}, getFile = false) =>
          this.stored[callname](params, getFile, true),
    });

    this.Storage = new Storage(`${this.appVersion}_Model`);
    this.Storage.Log = Log;

    this.endpointsWithErrorHandlingWhereCalled = [
      'callassistance',
      'submitgraph',
      'progressbar',
      'bonuspayment',
      'sendtfaresetcode',
      'mobilephoneverify',
      'tfareset',
    ];
    this.unhandledApiErrors = [
      'captchaRequired',
      'captchaWrong',
      'tfaloginrequired',
      'managed',
      'sound_active',
    ];
  }

  abortRunningCalls(apiCalls) {
    this.abortControllers.forEach((controller, path) => {
      if (
        !apiCalls ||
        (apiCalls && Array.isArray(apiCalls) && apiCalls.includes(path))
      ) {
        controller.abort();
        this.abortControllers.delete(path);
      }
    });
  }

  retrieveData(callname) {
    return async (params, getFile = false, live = false) => {
      if (!live) {
        if (this.Storage.has(callname)) return this.Storage.get(callname);
      }
      const result = await this.fetch(
        callname,
        params,
        getFile ? 'file' : 'json',
      );

      if (!result) return {};
      if (getFile) return result;
      // filter auth from rest so it is not stored accidently
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { success, auth, user, ...rest } = result;

      if (!success) return result;

      if (
        user &&
        ['login', 'account', 'resetpassword', 'tfa'].includes(callname)
      ) {
        this.appElement?.userUpdated(user);
      } else if (
        Object.keys(rest).length &&
        this.storageWhitelist.has(callname) &&
        this.storageWhitelist.get(callname)(params)
      ) {
        this.Storage.set(callname, rest);
      }
      return result;
    };
  }

  // eslint-disable-next-line complexity, max-statements, max-lines-per-function
  async fetch(path = '', parametersObject = {}, expected = 'json') {
    const abortController = new AbortController();
    this.abortControllers.set(path, abortController);
    const { signal } = abortController;
    const apiAction = `${this.apiHost}${path}`;
    const isFormData = parametersObject instanceof FormData;
    const headers = isFormData
      ? {}
      : {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        };
    const body = isFormData
      ? parametersObject
      : deepNestedObjectToQueryString({
          ...parametersObject,
          appVersion: this.appVersion,
        });

    let response;
    if (this.appElement) {
      this.appElement.isLoading = this.abortControllers.size > 0;
    }
    try {
      response = await fetch(apiAction, {
        signal,
        headers,
        body,
        credentials: 'include',
        method: 'POST',
      });
      this.abortControllers.delete(path);
      if (this.appElement) {
        this.appElement.isLoading = this.abortControllers.size > 0;
      }
      if (!response.ok) {
        if (window.unloaded || response.status === 0) {
          return null;
        }
        if (response.status === 404) {
          // eslint-disable-next-line no-alert
          alert('Not found. [404]');
        } else if (response.status === 500) {
          // eslint-disable-next-line no-alert
          alert('Internal Server Error. [500]');
        } else {
          // eslint-disable-next-line no-alert
          alert(`Uncaught Error.\n${response.statusText}`);
        }
        Log.error(new Error(response.statusText));
      }

      if (expected === 'file') {
        // prevent file downloads with error content
        if (
          response.headers.get('Content-Type').startsWith('application/json')
        ) {
          response = await response.json();
        } else {
          response = await response.blob();
        }
      } else {
        response = await response.json();
        response.sent = Object.assign(
          parametersObject instanceof FormData
            ? Object.fromEntries(parametersObject)
            : parametersObject,
          { path },
        );
      }
    } catch (err) {
      if (err.name !== 'AbortError' && err.name !== 'TypeError') {
        Log.error(err);
      }
    }

    if (!response || (!Object.keys(response).length && expected === 'json')) {
      return null;
    }

    return response.error
      ? this.onApiError(response.error, path, response)
      : response;
  }

  async resendAfterForceDialog(error, path, response) {
    const { value: retryWithForce } = await showAppDialog({
      html: window.T.error[error],
      variant: 'danger',
      titleText: window.T.term.error,
    });
    if (retryWithForce) {
      const { sent } = response;
      sent.force = 1;
      this.fetch(path, sent);
    }
  }

  showError(path, error) {
    const errorDictionary = i18n.rawText.error;

    let errorMsg =
      errorDictionary[error] && typeof errorDictionary[error] === 'string'
        ? errorDictionary[error]
        : '';

    errorMsg = errorDictionary[path]
      ? errorDictionary[path][error] || errorMsg
      : errorMsg;
    this.appElement.dispatchEvent(
      createAlertMessageEvent(
        errorMsg || `${window.T.error.unknownerror} ${error}`,
        'danger',
      ),
    );

    if (!errorMsg) {
      Log.error(new Error(`API returned error "${error}" calling ${path}`));
    }
  }

  onApiError(error, path, response) {
    if (
      this.unhandledApiErrors.includes(error) ||
      this.endpointsWithErrorHandlingWhereCalled.includes(path)
    ) {
      return response;
    }

    // error-key-specific errorHandling, see e.g. this.onAPIauthError()
    if (
      this[`onAPI${error}Error`] &&
      typeof this[`onAPI${error}Error`] === 'function'
    ) {
      this[`onAPI${error}Error`](error, path, response);
      // api-call-specific errorHandling, see e.g. this.tfaError()
    } else if (
      this[`${path}Error`] &&
      typeof this[`${path}Error`] === 'function'
    ) {
      this[`${path}Error`](error, path, response);
    } else {
      this.showError(path, error);
      return null;
    }
    return response;
  }

  onAPItfarequiredError() {
    Router.navigate('/tfa');
  }

  onAPIauthError() {
    this.appElement.dispatchEvent(createLogoutEvent());
    this.Storage.removeAll();
  }

  onAPIquotareachedError() {
    this.abortRunningCalls();
    this.appElement.dispatchEvent(
      createAlertMessageEvent(window.T.error.quota_reached, 'danger'),
    );
    Router.updateURLonly('/');
  }

  onAPInotallowedError(error, path) {
    this.appElement.view?.remove();
    this.abortRunningCalls();
    this.showError(path, error);
  }

  loginError(error, path) {
    // the same wich captchaRequired & captchaWrong is needed for 'forgotpassword'!
    const errors = ['passwordtooold', 'captchaRequired', 'captchaWrong'];
    return errors.includes(error) ? null : this.showError(path, error);
  }

  onAPIpriceCheckLowError(error, path, response) {
    this.resendAfterForceDialog(error, path, response);
  }
}

const Model = new ModelClass();
export { Model };
