import type { YmdRoute } from '@yoummday/ymmd-router/dist/types';

export default <Record<string, YmdRoute>>{
  '/mobiledocupload/:token': {
    as: 'mobiledocupload',
    isAuthRequired: false,
    isAllowedWithoutVerifiedEmail: true,
    hasPageTracking: true,
    hasHeader: false,
    hasAsideNavigation: false,
    module: () => import('./'),
  },
};
