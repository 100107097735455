import { User } from '../../modules/User';
import { API } from '../../modules/api';
import { authorizationMiddleWare } from '../../';
import { SalesToolService } from './service/sales-tool-service';

export default <Record<string, TalentRoute>>{
  '/salestool': {
    as: 'salestool',
    hasAsideNavigation: false,
    hasAsideSalesToolNavigation: true,
    module: async () => {
      API.eject(authorizationMiddleWare);
      const isAdminAccessAllowed = await SalesToolService.adminAccessAllowed();
      User.updateUser({ isSalesToolAdmin: isAdminAccessAllowed });
      return await import('./');
    },
  },
};
