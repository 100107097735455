/* eslint-disable max-statements */
import { API } from '../../../modules/api';
import { Log } from '@yoummday/ymmd-logger';
import type {
  AudioStats,
  ConnectionStats,
  ParsedMetrics,
} from '../types/phoneInstance.def';

export const aggregateAverage: ParsedMetrics = {
  qualityScore: 0,
  qualityRatingFactor: 0,
  packetLossRate: 0,
  averageJitter: 0,
  averageRoundTripTime: null,
  inboundBitrate: 0,
  outboundBitrate: 0,
  packetsDiscarded: 0,
  totalPacketsReceived: 0,
  totalPacketsSent: 0,
};

const aggregateArray: ParsedMetrics[] = [];

export class PhoneInstanceService {
  /* eslint-disable no-use-before-define */
  private static instance: PhoneInstanceService;
  private baseUrl = '/web-rtc-report';

  // eslint-disable-next-line max-lines-per-function
  public calculateCallStats(parsedStats: {
    audio: AudioStats;
    connection: ConnectionStats;
  }): ParsedMetrics {
    /*
		Official resource(s) for calculating MOS score:
			https://ieeexplore.ieee.org/document/9351640
			https://www.itu.int/rec/dologin_pub.asp?lang=e&id=T-REC-G.107-201506-I!!PDF-E&type=items
			https://www.itu.int/ITU-T/2005-2008/com12/emodelv1/tut.htm
			https://www.itu.int/rec/T-REC-G.107
			https://webrtc.github.io/samples/
			https://webrtc.org/
		*/

    const audioData = parsedStats && parsedStats?.audio;
    const connectionData = parsedStats && parsedStats?.connection;
    const [inboundAudio] = audioData.inbound ?? {};
    const [outboundAudio] = audioData.outbound ?? {};
    const roundTripTime = connectionData.currentRoundTripTime || 0;
    const packetsDiscarded = inboundAudio.packetsDiscarded || 0;
    const totalPacketsReceived = inboundAudio.packetsReceived || 0;
    const totalPacketsSent = outboundAudio.packetsSent || 0;
    const packetsLost = inboundAudio.packetsLost || 0;
    const packetLossRate =
      totalPacketsReceived > 0 ? packetsLost / totalPacketsReceived : 0;
    const averageJitter = inboundAudio.jitter || 0;
    const formattedRTTValue = (roundTripTime * 1000).toFixed(2);
    const inboundBitrate = inboundAudio.bitrate || 0;
    const outboundBitrate = outboundAudio.bitrate || 0;

    const getAbsoluteDelay = (RTT: number, jitterBufferDelay: number): number =>
      RTT / 2 + jitterBufferDelay + 20;

    const Ro = 148;
    const Is = 0;
    let Id;
    const A = 0;
    const Ta = getAbsoluteDelay(roundTripTime, averageJitter);
    const Ppl = packetLossRate * 100;

    const Iee = 10.2 + (132 - 10.2) * (Ppl / (Ppl + 4.3));

    if (Ta <= 100) {
      Id = 0;
    } else {
      const x = (Math.log(Ta) - Math.log(100)) / Math.log(2);
      Id =
        1.48 *
        25 *
        ((1 + x ** 6) ** (1 / 6) - 3 * (1 + (x / 3) ** 6) ** (1 / 6) + 2);
    }

    const Rx = Ro - Is - Id - Iee + A;
    const R = Rx / 1.48;
    const MOS = 1 + 0.035 * R + R * (R - 60) * (100 - R) * 0.000007;

    return {
      qualityScore: parseFloat(MOS.toFixed(2)),
      qualityRatingFactor: parseFloat(R.toFixed(2)),
      packetLossRate: parseFloat((packetLossRate * 100).toFixed(4)),
      averageJitter: parseFloat(averageJitter.toFixed(4)),
      averageRoundTripTime: parseFloat(formattedRTTValue),
      inboundBitrate: parseFloat(inboundBitrate.toFixed(2)),
      outboundBitrate: parseFloat(outboundBitrate.toFixed(2)),
      packetsDiscarded,
      totalPacketsReceived,
      totalPacketsSent,
    };
  }

  public calculateAggregateStats(rawStats: ParsedMetrics) {
    aggregateArray.push(rawStats);

    for (const stat in rawStats) {
      if (stat in aggregateAverage) {
        const validValues = aggregateArray
          .map((val) => stat in val && val[stat])
          .filter((value) => value && value !== null);
        aggregateAverage[stat] =
          validValues.length === 0
            ? null
            : validValues.reduce((acc, value) => acc + value, 0) /
              validValues.length;
      }
    }
    return aggregateAverage;
  }
  public async sendCallStats(
    parsedData: ParsedMetrics,
    callId: string,
    timestamp: number,
  ) {
    if (!callId) return;
    const body = {
      callId,
      data: parsedData,
      timestamp,
      schemaVersion: 1,
    };

    const { response, error } = await API.POST(`${this.baseUrl}/v1/data`, {
      body,
    });
    Log.log('response', response);
    if (error) throw new Error(error);
  }

  public async sendAggregateCallStats(
    data: ParsedMetrics,
    callId: string,
    timestamp: number,
  ) {
    if (!callId) return;
    const body = {
      callId,
      data,
      timestamp,
      schemaVersion: 1,
    };

    const { response, error } = await API.POST(
      `${this.baseUrl}/v1/aggregation`,
      {
        body,
      },
    );
    Log.log('response', response);
    if (error) throw new Error(error);
  }

  public resetAggregateData(): void {
    if (aggregateArray) {
      aggregateArray.length = 0;
      Object.entries(aggregateAverage).forEach(([key]) => {
        if (key in aggregateAverage) {
          aggregateAverage[key as keyof ParsedMetrics] = 0;
        }
      });
    }
  }

  public static getInstance(): PhoneInstanceService {
    if (!PhoneInstanceService.instance) {
      PhoneInstanceService.instance = new PhoneInstanceService();
    }
    return PhoneInstanceService.instance;
  }
}
