export default <Record<string, TalentRoute>>{
  '/webcamsession': {
    as: 'webcamsession',
    needsOnboarding: false,
    hasPageTracking: true,
    hasHeader: false,
    hasAsideNavigation: false,
    module: () => import('./'),
  },
};
