export default <Record<string, TalentRoute>>{
  '/profile/:group': {
    as: 'profile',
    hasRocketchat: true,
    module: () => import('./'),
  },
  '/profile': {
    as: 'profile',
    hasRocketchat: true,
    module: () => import('./'),
  },
};
