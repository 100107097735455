export default <Record<string, TalentRoute>>{
  '/welcome': {
    as: 'welcome',
    needsOnboarding: false,
    needsNoOnboarding: true,
    isAllowedWithoutVerifiedEmail: true,
    hasPageTracking: true,
    module: () => import('./'),
  },
};
