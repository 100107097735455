import type { YmdRoute } from '@yoummday/ymmd-router/dist/types';

export default <Record<string, YmdRoute>>{
  '/worthknowing/:topic': {
    as: 'worthknowing',
    hasAsideNavigation: true,
    hasHeader: true,
    module: () => import('./'),
  },
};
