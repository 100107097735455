import { API } from '../../../modules/api';
import type {
  Report,
  SalesSuccessReportRights,
  APIResponse,
  ReportOptions,
  TariffReportOptions,
  HistoryGroup,
} from '../types';

export class SalesToolService {
  private static readonly baseUrl = '/sales-report';

  static async getAccessLevel(): Promise<
    APIResponse<SalesSuccessReportRights>
  > {
    const { data } = await API.GET(`${this.baseUrl}/permission`);
    return data;
  }
  static async basicAccessAllowed(): Promise<boolean> {
    const { talent } = (await this.getAccessLevel()) || {};
    return !!talent;
  }
  static async adminAccessAllowed(): Promise<boolean> {
    const { admin } = (await this.getAccessLevel()) || {};
    return !!admin;
  }

  static async getOptions(): Promise<APIResponse<ReportOptions>> {
    const url = `${this.baseUrl}/options`;
    const { response, data } = await API.GET(url, {});
    return { response, data };
  }

  static async overrideOptions(
    category: TariffReportOptions,
    file: File,
  ): Promise<APIResponse<void>> {
    const url = `${this.baseUrl}/options/${category}`;
    const formData = new FormData();
    formData.append('file', file);

    const { response, data } = await API.POST(url, { body: formData });
    return { response, data };
  }

  static async downloadOptions<T extends TariffReportOptions>(
    category: T,
  ): Promise<APIResponse<Blob>> {
    const url = `${this.baseUrl}/options/${category}/download`;

    const { response, data } = await API.GET(url, { parseAs: 'blob' });
    return { response, data };
  }

  static async getReportHistory(): Promise<APIResponse<HistoryGroup>> {
    const url = `${this.baseUrl}/report`;
    const { response, data } = await API.GET(url, {});
    return { response, data };
  }

  static async createReport(reportData: Report): Promise<APIResponse<Report>> {
    const url = `${this.baseUrl}/report`;
    const { response, data } = await API.POST(url, { body: reportData });
    return { response, data };
  }
}
