export default <Record<string, TalentRoute>>{
  '/language-evaluation/results/:sessionId': {
    as: 'language-evaluation',
    hasHeader: false,
    hasAsideNavigation: false,
    needsOnboarding: false,
    module: () => import('./'),
  },
  '/language-evaluation': {
    as: 'language-evaluation',
    hasHeader: false,
    hasAsideNavigation: false,
    needsOnboarding: false,
    module: () => import('./'),
  },
};
