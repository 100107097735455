import type { YmdRoute } from '@yoummday/ymmd-router/dist/types';

export default <Record<string, YmdRoute>>{
  '/login': {
    as: 'login',
    hasHeader: false,
    hasAsideNavigation: false,
    isAuthRequired: false,
    hasPageTracking: true,
    module: () => import('./'),
  },
};
