/* eslint-disable max-lines */
/* eslint-disable camelcase */
import JsSIP from 'jssip';
import { DTMF_TRANSPORT } from 'jssip/lib/Constants.js';
import type { PhoneInstance, EventData } from '../types/phoneInstance.def';
import { PhoneMonitorService } from '../services/phoneMonitor.service';
import { PhoneInstanceService } from '../services/phoneInstance.service';
import { WebRTCStats } from '@peermetrics/webrtc-stats';
import { ringtone } from '../ringtone';
import { notification } from '@yoummday/ymmd-platform-core/utils';
import { createAlertMessageEvent } from '@yoummday/ymmd-platform-core/comp/app-shell';
import { Log } from '@yoummday/ymmd-logger';

let workerPort: MessagePort;
const phoneMonitorService = PhoneMonitorService.getInstance();
const phoneInstanceService = PhoneInstanceService.getInstance();

export const phoneInstance: PhoneInstance = {
  guis: [],
  sessionId: null,
  user: '',
  password: '',
  callId: '',
  ua: null,
  incomingAudio: new Audio(),
  webRTCStats: null,
  autoAnswer: false,
  isCallTerminated: false,
  qualityScore: 0,
  qualityScores: [],
  answeroptions: {
    pcConfig: {
      iceServers: [
        {
          urls: [
            'stun:stun.l.google.com:19302',
            'stun:stun1.l.google.com:19302',
          ],
        },
      ],
    },
    mediaConstraints: {
      audio: true,
      video: false,
    },
    rtcAnswerConstraints: {
      offerToReceiveAudio: true,
      offerToReceiveVideo: false,
    },
    sessionTimersExpires: 180,
  },
  call: null,
  _state: 'offline',
  get state() {
    return this._state;
  },
  set state(state) {
    const previousState = this._state;
    switch (state) {
      case 'online':
        window.removeEventListener('beforeunload', this.warnBeforeunload);
        if (previousState === 'talking' || previousState === 'ringing') {
          this.call = null;
          phoneInstance.ua?.register();
          this.guis[0].toggleAnswerDialog();
        }
        ringtone.pause();
        break;
      case 'ringing': {
        try {
          const playRingtone = ringtone.play();
          playRingtone.catch((error) => {
            phoneMonitorService.addLog(error);
          });
        } catch (error) {
          Log.log(error);
        }
        if (!this.autoAnswer) this.guis[0].toggleAnswerDialog(true);
        break;
      }
      case 'talking':
        ringtone.pause();
        this.incomingAudio.play();
        break;
      default:
        break;
    }
    this._state = state;
    this.guis.forEach((gui) => {
      gui.requestUpdate();
    });
  },
  toggleAutoAnswer() {
    this.autoAnswer = !this.autoAnswer;
    this.guis.forEach((gui) => {
      gui.requestUpdate();
    });
  },
  warnBeforeunload(e: BeforeUnloadEvent) {
    e.preventDefault();
    e.returnValue = false; // chrome wants this
  },
  // eslint-disable-next-line max-lines-per-function
  start() {
    this.isCallTerminated = false;
    phoneInstance.qualityScores = [];
    if (!this.ua) {
      const socket = new JsSIP.WebSocketInterface(
        import.meta.env.VITE_PHONE_SOCKET,
      );
      this.ua = new JsSIP.UA({
        password: this.password,
        sockets: [socket],
        uri: `sip:${this.user}@sip.yoummday.com`,
      });

      this.ua
        .on('unregistered', () => {
          this.state = this.state === 'foreign' ? this.state : 'offline';
        })
        .on('registered', ({ response }) => {
          const { call_id } = response;
          this.state = 'online';
          this.sessionId = call_id;
          workerPort.postMessage({ sessionId: phoneInstance.sessionId });
        })
        .on('registrationFailed', () => {
          this.state = 'offline';
        })
        .on('registrationExpiring', () => {
          this.ua?.register();
        })
        // eslint-disable-next-line max-lines-per-function
        .on('newRTCSession', async ({ session }) => {
          this.call = session;
          this.state = 'ringing';
          window.addEventListener('beforeunload', this.warnBeforeunload);
          session
            .on('ended', (data: EventData['FailedOrEnded']) => {
              try {
                phoneMonitorService.addLog(`onended: ${JSON.stringify(data)}`);
              } catch (error) {
                phoneMonitorService.addLog(`onended: ${error}`);
              }
              this.state = 'online';
            })
            .on('failed', (data: EventData['FailedOrEnded']) => {
              try {
                phoneMonitorService.addLog(`onfailed: ${JSON.stringify(data)}`);
              } catch (error) {
                Log.log(error);
              }
              this.state = 'online';
            })
            // eslint-disable-next-line max-lines-per-function
            .on('peerconnection', (data: EventData['PeerConnection']) => {
              try {
                phoneMonitorService.addLog(
                  `onpeerconnection: ${JSON.stringify(data)}`,
                );
              } catch (error) {
                phoneMonitorService.addLog(`onpeerconnection: ${error}`);
              }
              const pc = data.peerconnection;
              phoneInstanceService.resetAggregateData();

              Object.entries({
                iceconnectionstatechange: 'iceConnectionState',
                signalingstatechange: 'signalingState',
                connectionstatechange: 'connectionState',
              }).forEach(([eventName, propertyToLog]) => {
                pc.addEventListener(eventName, () => {
                  phoneMonitorService.addLog(
                    `${eventName}: ${pc[propertyToLog as keyof RTCPeerConnection]}`,
                  );
                });
              });

              pc.addEventListener('track', (e) => {
                try {
                  phoneMonitorService.addLog(`ontrack: ${JSON.stringify(e)}`);
                } catch (error) {
                  phoneMonitorService.addLog(`ontrack: ${error}`);
                }
                const [stream] = e.streams;
                this.incomingAudio.srcObject = stream;
                this.state = 'talking';
                this.webRTCStats = new WebRTCStats({
                  getStatsInterval: 5000,
                  rawStats: false,
                  statsObject: true,
                  filteredStats: false,
                  remote: true,
                  wrapGetUserMedia: false,
                  debug: false,
                  logLevel: 'warn',
                });
                this.webRTCStats?.on('stats', (ev) => {
                  phoneMonitorService.startMonitoring(
                    ev.data,
                    phoneInstance.callId,
                  );
                });
                this.webRTCStats?.addConnection({
                  pc,
                  peerId: '1',
                  remote: false,
                });
              });
            })
            .on('icecandidate', (data: EventData['IceCandidate']) => {
              if (data.candidate.candidate.indexOf('srflx') !== -1) {
                data.ready();
              }
            })
            .on('sdp', (data: EventData['SdpEvent']) => {
              if (data.originator === 'local' && data.sdp) {
                data.sdp = data.sdp.replace(
                  /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}\.local/giu,
                  '192.168.1.2',
                );
              }
              try {
                phoneMonitorService.addLog(`onsdp: ${JSON.stringify(data)}`);
              } catch (error) {
                Log.log(error);
              }
            });

          if (document.visibilityState === 'hidden') {
            const banner = notification(window.T.message.event.call_new);
            document.addEventListener('visibilitychange', () => {
              if (document.visibilityState === 'visible' && banner) {
                banner.close();
              }
            });
          }

          try {
            this.answeroptions.mediaStream =
              await navigator.mediaDevices.getUserMedia({ audio: true });
          } catch {
            session.terminate();
            this.webRTCStats?.removeAllPeers();
            this.guis[0].dispatchEvent(
              createAlertMessageEvent(
                window.T.error.allow_microphone,
                'danger',
              ),
            );

            return;
          }

          if (this.autoAnswer) {
            setTimeout(() => {
              this.answer();
            }, 2000);
          }
        });
    }
    if (!this.ua.isConnected()) this.ua.start();
  },
  answer(e) {
    if (e) e.preventDefault();
    if (this.call) this.call.answer(this.answeroptions);
  },
  terminate(e) {
    if (this.call) {
      try {
        if (e) e.preventDefault();
        phoneMonitorService.endMonitoring(phoneInstance.callId);
        this.call.terminate();
        this.isCallTerminated = true;
      } catch (error) {
        Log.log(error);
      }
    }
    if (!this.isCallTerminated) {
      phoneMonitorService.endMonitoring(phoneInstance.callId);
    }
    if (this.answeroptions.mediaStream) {
      this.answeroptions.mediaStream
        .getTracks()
        .forEach((track) => track.stop());
    }
    this.webRTCStats?.removeAllPeers();
  },
  sendDTMF(key) {
    this.call?.sendDTMF(key, {
      transportType: DTMF_TRANSPORT.RFC2833,
    });
  },
};

if (typeof SharedWorker !== 'undefined') {
  try {
    const { port } = new SharedWorker(
      new URL('../phoneWorker', import.meta.url).href,
    );
    workerPort = port;
    workerPort.onmessage = ({ data: sessionId = '' }) => {
      if (sessionId === phoneInstance.sessionId || !phoneInstance.ua) return;

      if (
        sessionId &&
        phoneInstance.ua.isRegistered() &&
        !['talking', 'ringing'].includes(phoneInstance.state)
      ) {
        phoneInstance.state = 'foreign';
        phoneInstance.ua.unregister();
      }

      if (!sessionId && !phoneInstance.ua.isRegistered()) {
        phoneInstance.ua.register();
      }
    };
    const unregisterOnUnload = () => {
      if (phoneInstance.ua && phoneInstance.ua.isRegistered()) {
        phoneInstance.ua.unregister();
        workerPort.postMessage('');
      }
      workerPort.postMessage({ cmd: 'closed' });
    };
    window.addEventListener('beforeunload', unregisterOnUnload);
  } catch (err) {
    Log.error?.(err as Error);
  }
}
