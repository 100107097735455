export default <Record<string, TalentRoute>>{
  '/onboarding/:step': {
    as: 'onboarding',
    needsOnboarding: false,
    needsNoOnboarding: true,
    hasPageTracking: true,
    module: () => import('./'),
  },
  '/onboarding': {
    as: 'onboarding',
    needsOnboarding: false,
    needsNoOnboarding: true,
    hasPageTracking: true,
    module: () => import('./'),
  },
};
