import dingdong from './ring.mp3';

const ringtone = new Audio(dingdong);
ringtone.loop = true;
const reset = (e: Event) => {
  (e.currentTarget as HTMLAudioElement).currentTime = 0;
};
ringtone.addEventListener('pause', reset);

export { ringtone };
